import Swup from 'swup';
import SwupJsPlugin from '@swup/js-plugin';
import { TweenMax } from 'gsap/all';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Collapse from 'bootstrap/js/dist/collapse';
import { debounce, getAllCSSVariableNames, shuffle } from './Utilities';

class App {
  constructor() {
    this.headerCopied = false;
    document.addEventListener('DOMContentLoaded', this.init.bind(this));
  }

  init() {
    this.logoLetters = document.querySelectorAll('.logo__letter');
    this.header = document.querySelector('#siteHeader');
    this.performingArt = document.querySelector('#performing');
    this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.windowHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 95;
    this.scrollPositionY = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset || 0;
    this.scrollPercentage = this.scrollPositionY / this.windowHeight;
    this.initializeLogoAnimation();
    if (!this.headerCopied) {
      this.copyHeaderInOffcanvas();
    }
    this.setRandomBackgrounds();
    this.setRandomBackgrounds('.randomBackgroundHover', 'bg-hover');
    this.fitRestOfScreen();
    this.artistImageSlider();
  }

  start() {
    window.addEventListener('resize', this.windowResize.bind(this));
    window.addEventListener('scroll', debounce(this.windowScroll.bind(this)));
  }

  windowScroll () {
    this.scrollPositionY = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset || 0;
    this.scrollPercentage = this.scrollPositionY / this.windowHeight;
    this.animateLogo();
  }

  windowResize() {
    this.windowHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 95;
    this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  animateLogo() {
    if (!this.header.classList.contains('site__header--small')) {
      if (this.scrollPositionY + 25 >= this.windowHeight) {
        if (!this.header.classList.contains('site__header--sticky')) {
          this.header.classList.remove('site__header--fullscreen');
          this.header.classList.add('site__header--sticky');
        }
      } else {
        if (!this.header.classList.contains('site__header--fullscreen')) {
          this.header.classList.remove('site__header--sticky');
          this.header.classList.add('site__header--fullscreen');
        }
      }

      this.logoLetters.forEach((letter, i) => {
        if (letter.getAttribute('data-final-left-position')) {
          let finalLeftPos = parseInt(letter.getAttribute('data-final-left-position'));
          let initialLeftPos = parseInt(letter.getAttribute('data-initial-left-position'));
          let movingFactor = parseInt(letter.getAttribute('data-moving-factor'));
          let newLeftPos = movingFactor * this.scrollPercentage + initialLeftPos;
          let condition = newLeftPos < finalLeftPos;
          if (movingFactor < 0) {
            condition = newLeftPos > finalLeftPos;
          }
          if (condition) {
            letter.style.left = newLeftPos + '%';
          }
        }

        if (this.scrollPositionY >= letter.getAttribute('data-initial-offset')) {
          if (letter.getAttribute('data-moved') === null) {
            letter.style.top = 15 + 'px';
            letter.style.position = 'fixed';
            letter.setAttribute('data-moved', 'true');
          }
        } else {
          if (letter.getAttribute('data-moved') === 'true') {
            letter.style.position = 'absolute';
            letter.style.removeProperty('top');
            letter.removeAttribute('data-moved');
          }
        }

        if (this.scrollPositionY >= this.performingArt.offsetTop - 100) {
          this.performingArt.style.opacity = 0;
        } else {
          this.performingArt.style.opacity = 1;
        }
      })
    }
  }

  copyHeaderInOffcanvas() {
    let headerCopy = document.querySelector('#siteHeader').cloneNode(true);
    headerCopy.classList.remove('site__header--fullscreen');
    document.querySelector('#mainNav').prepend(headerCopy);
    this.headerCopied = true;
  }

  initializeLogoAnimation() {
    this.logoLetters.forEach((el) => {
      el.setAttribute('data-initial-offset', el.offsetTop - 15);
    });
  }

  setRandomBackgrounds(querySelector = '.randomBackground', replacement = 'bg') {
    const rootVariables = getAllCSSVariableNames().filter(a => a.includes('ohlala-theme') && !a.includes('rgb') && !a.includes('black'));
    const randomBackgroundElements = document.querySelectorAll(querySelector);
    const numberOfBackgrounds = rootVariables.length;
    let counter = 0;
    shuffle(rootVariables);
    randomBackgroundElements.forEach((randomBackgroundElement, index) => {
      if (counter < numberOfBackgrounds) {
        randomBackgroundElement.classList.add(rootVariables[counter].replace('--ohlala', replacement))
      } else {
        counter = 0;
      }
      counter++;
    });
  }

  fitRestOfScreen() {
    const elements = document.querySelectorAll('.fitRestOfScreen');
    elements.forEach(element => {
      element.style.height = 'calc(100vh - ' + this.header.clientHeight + 'px)';
    });
  }

  artistImageSlider() {
    if (this.windowWidth >= 992) {
      const sliderElements = document.querySelectorAll('.sliderElement');
      const imageHolder = document.getElementById('projectImageHolder');
      sliderElements.forEach(element => {
        imageHolder.append(element.querySelector('.sliderElementImage'));
        element.addEventListener('mouseenter', () => {
          const image = document.getElementById(element.getAttribute('data-target-image'));
          imageHolder.querySelectorAll('.sliderElementImage').forEach(sliderElement => {
            if (image.id === sliderElement.id) {
              sliderElement.classList.remove('d-lg-none');
            } else {
              sliderElement.classList.add('d-lg-none');
            }
          })
        });
        element.addEventListener('mouseleave', () => {
          const image = document.getElementById(element.getAttribute('data-target-image'));
          imageHolder.querySelectorAll('.sliderElementImage').forEach(sliderElement => {
            if (image.id !== sliderElement.id) {
              sliderElement.classList.add('d-lg-none');
            } else {
              sliderElement.classList.remove('d-lg-none');
            }
          })
        });
      });
    }
  }
}

 // btn back with language
 document.addEventListener("DOMContentLoaded", function () {
  const languageCodes = ["en", "de"]; // Langues avec préfixe
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const currentLang = languageCodes.includes(pathSegments[0]) ? pathSegments[0] : "fr"; // Détecte la langue actuelle
  const currentPage = window.location.pathname; // URL complète sans domaine

  // Supprime le préfixe de langue de l'URL actuelle
  const cleanCurrentPage = languageCodes.includes(pathSegments[0]) ? '/' + pathSegments.slice(1).join('/') : currentPage;

  // Récupérer l'historique depuis sessionStorage
  let historyStack = JSON.parse(sessionStorage.getItem("historyStack")) || [];

  // Vérifier si la page est déjà dans l'historique (même URL)
  const isPageInHistory = historyStack.some(page => page.url === cleanCurrentPage);
  const lastPage = historyStack[historyStack.length - 1];

  // Si la dernière page est la même mais avec une langue différente
  if (historyStack.length > 0 && lastPage && lastPage.url === cleanCurrentPage && lastPage.lang !== currentLang) {
      // Mise à jour de la langue uniquement
      lastPage.lang = currentLang;
      console.log('🔄 Mise à jour de la langue :', {
          url: cleanCurrentPage,
          newLang: currentLang,
          fullHistory: historyStack
      });
  } 
  // Si la page n'est pas déjà la dernière de l'historique
  else if (!lastPage || lastPage.url !== cleanCurrentPage) {
      // Ajouter la page actuelle à l'historique
      historyStack.push({ url: cleanCurrentPage, lang: currentLang });
      if (historyStack.length > 15) {
          historyStack.shift(); // Garde seulement les 15 dernières pages
      }
      console.log('📝 Nouvelle page ajoutée à l\'historique :', {
          url: cleanCurrentPage,
          lang: currentLang,
          historySize: historyStack.length,
          fullHistory: historyStack
      });
  } else {
      console.log('ℹ️ Page déjà présente dans l\'historique, pas de modification');
  }

  sessionStorage.setItem("historyStack", JSON.stringify(historyStack));
});

window.handleBackButton = function (event) {
    event.preventDefault();

    let historyStack = JSON.parse(sessionStorage.getItem("historyStack")) || [];
    const languageCodes = ["en", "de"];
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    const currentLang = languageCodes.includes(pathSegments[0]) ? pathSegments[0] : "fr";

    console.log('🔍 État actuel de l\'historique :', {
        totalPages: historyStack.length,
        fullHistory: historyStack,
        currentLang: currentLang
    });

    // Fonction pour construire l'URL avec la bonne langue
    function buildUrl(lang, url) {
        let cleanedUrl = url.replace(/^\/(en|de)\//, '/');
        return lang === "fr" ? cleanedUrl : `/${lang}${cleanedUrl}`;
    }

    if (historyStack.length < 2) {
        console.warn(`⚠️ Pas assez d'historique, retour à l'accueil en ${currentLang}`);
        window.location.href = buildUrl(currentLang, "/");
        return;
    }

    // Supprimer la page actuelle de l'historique
    historyStack.pop();
    
    // Récupérer la page précédente
    const prevPage = historyStack[historyStack.length - 1];

    console.log('🔙 Navigation retour :', {
        from: {
            url: window.location.pathname,
            lang: currentLang
        },
        to: prevPage
    });

    // Sauvegarder l'historique AVANT la navigation
    sessionStorage.setItem("historyStack", JSON.stringify(historyStack));

    // Rediriger vers la page précédente avec la langue actuelle
    window.location.href = buildUrl(currentLang, prevPage.url);
};

// Scrolling effect artistes page (mobile only)
document.addEventListener("DOMContentLoaded", function () {
  const images = document.querySelectorAll('.artist-list__image');
  let isMobileActive = false;

  function isMobile() {
      return window.innerWidth <= 768; // Seuil pour mobile (modifiable)
  }

  function updateImageEffect() {
      if (!isMobile()) return; // Stop l'effet si l'écran est trop large

      let windowHeight = window.innerHeight;

      images.forEach(image => {
          let rect = image.getBoundingClientRect(); // Position de l'image
          let imagePosition = rect.top + (rect.height / 2); // Centre de l'image
          let scrollPercentage = (imagePosition / windowHeight) * 100; // Position en %

          if (scrollPercentage <= 70) {
              image.style.filter = "none"; // Effet désactivé
          } else if (scrollPercentage > 100) {
              image.style.filter = "invert(1) grayscale(1)"; // Effet activé
          }
      });
  }

  function handleScroll() {
      if (isMobileActive) {
          updateImageEffect();
      }
  }

  function checkDevice() {
      if (isMobile()) {
          if (!isMobileActive) {
              isMobileActive = true;
              window.addEventListener("scroll", handleScroll);
              updateImageEffect(); // Appliquer immédiatement
          }
      } else {
          if (isMobileActive) {
              isMobileActive = false;
              window.removeEventListener("scroll", handleScroll);
              images.forEach(image => (image.style.filter = "none")); // Reset l'effet
          }
      }
  }

  // Vérification initiale et écoute du resize
  checkDevice();
  window.addEventListener("resize", checkDevice);
});




export default App;
