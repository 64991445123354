export const debounce = (fn) => {
  let frame;
  return (...params) => {
    if (frame) {
      cancelAnimationFrame(frame);
    }
    frame = requestAnimationFrame(() => {
      fn(...params);
    });
  }
};

export const getAllCSSVariableNames = (styleSheets = document.styleSheets) => {
  let cssVars = [];
  for (let i = 0; i < styleSheets.length; i++) {
    try {
      for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
        try {
          for (let k = 0; k < styleSheets[i].cssRules[j].style.length; k++) {
            let name = styleSheets[i].cssRules[j].style[k];
            if (name.startsWith('--') && cssVars.indexOf(name) === -1) {
              cssVars.push(name);
            }
          }
        } catch (error) {
        }
      }
    } catch (error) {
    }
  }
  return cssVars;
}

export const shuffle = array => {
  array.sort(() => Math.random() - 0.5);
}



